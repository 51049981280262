<template>
  <div class="wrapper">
    <div class="pay-info" v-if="entity && model && reference && value && date">
      <div class="page-subtitle">
        Por favor, prossiga com o pagamento do sinal através dos seguintes dados
        de referência de multibanco:
      </div>
      <div class="mb-info">
        <div class="label">Entidade:</div>
        <div class="value">{{ entity }}</div>

        <div class="label">Referência:</div>
        <div class="value">{{ reference }}</div>

        <div class="label">Valor:</div>
        <div class="value">{{ formatPriceNumber(value, 2, "€") }}</div>

        <div class="label">Data limite de pagamento:</div>
        <div class="value">{{ date }}</div>
      </div>
      <div class="small-text">
        Enviamos para o seu email o resumo da encomenda e os dados para o
        pagamento multibanco.
      </div>
      <div class="page-text">Encomenda {{ model }}</div>
      <div class="page-text bigger">Obrigado pela sua confiança</div>
    </div>
    <div v-else class="page-subtitle">
      O processo de reserva foi aberto numa nova janela.
    </div>
  </div>
</template>

<script>
import { formatPriceNumber } from "@/functions/general.js";
export default {
  name: "ReservationRedirect",
  components: {},
  methods: {
    formatPriceNumber,
  },
  computed: {
    entity() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.query.hasOwnProperty("entidade")
        ? this.$route.query.entidade
        : "";
    },
    model() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.query.hasOwnProperty("model")
        ? this.$route.query.model
        : "Carro";
    },
    reference() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.query.hasOwnProperty("referencia")
        ? this.$route.query.referencia
        : "";
    },
    value() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.query.hasOwnProperty("valor")
        ? this.$route.query.valor
        : "";
    },
    date() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.query.hasOwnProperty("dt_payment_limit")
        ? this.$route.query.dt_payment_limit
        : "";
    },
  },
  data() {
    return {
      cta: [
        {
          buttonLink: "/",
          buttonLabel: "Voltar",
          btnClass: "hero-btn",
          anchor: true,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.pay-info {
  margin: auto;
  text-align: left;
  margin-bottom: 2rem;
  @include respond-to-min("md") {
    max-width: 60%;
  }
}
.mb-info {
  display: grid;
  grid-template-columns: auto auto;
  @include respond-to-min("md") {
    max-width: 60%;
  }
  .label {
    margin-top: 0;
    grid-column-start: 1;
    grid-column-end: 2;
    font-weight: $bold;
  }
  .value {
    margin-top: 0;
    grid-column-start: 2;
    grid-column-end: 3;
  }
}
.small-text {
  margin-bottom: 0 !important;
  color: $tmeGrey3;
}
.page-subtitle {
  margin-top: 4rem;
}
.page-text {
  @include style-text(
    $fontRegular,
    $bigFontSize,
    $regular,
    $lineHeight,
    $primaryColor,
    none,
    0,
    0
  );
  &.bigger {
    margin-top: 0;
    font-size: $biggerFontSize;
  }
}
.back-wrapper {
  display: flex;
  justify-content: center;
  margin: 4rem;
  .btn-back {
    color: $primaryColor;
    cursor: pointer;
    display: flex;
    font-size: $mainFontSize;
    padding: 0.75rem 1.5rem;
    text-align: center;
    text-decoration: none;
    transition: 0.05s all ease;
    width: fit-content;
    min-height: 55px;
    place-items: center;
    justify-content: center;
    background-color: $primaryColor;
    border: thin solid $primaryColor;
    color: $primaryWhiteColor;
    width: 200px;
  }
}

.not-found-title {
  font-size: calc($biggestFontSize * 2);
  text-transform: uppercase;
  margin: 4rem;
  &::after {
    display: block;
    clear: both;
    content: "";
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 150px;
    height: 1px;
    width: 30%; /* or 100px */
    border-bottom: 4px solid $brandColor;
    margin: 0 auto;
    padding: 4px 0px;
  }
}
</style>
